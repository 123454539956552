import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap, flatMap, } from 'rxjs/operators';
import { LoadingController, ToastController } from '@ionic/angular';

declare var $: any;

@Injectable({
    providedIn: 'root'
  })
export class APIHelperService {

    public observable: Observable<any>;
    constructor(
        private http: HttpClient,
        private toastController: ToastController,
        private loadingController: LoadingController
    ) { }

    public post(url: string, body: any, httpHeaders: any): Observable<any> {
            
            return this.http.post<any>(url, body, httpHeaders)
            .pipe(
                tap( response => {
                }
                ),
                catchError(this.handleError)
            );
        
    }

    public put(url: string, body: any, httpHeaders: any): Observable<any> {

        return this.http.put<any>(url, body, httpHeaders).pipe(
            tap( response => {
                //console.log("response", response);
            }),
            catchError(this.handleError)
        );
    }

    public get(url: string, httpHeaders: any, isLoading: Boolean = true): Observable<any> {
        if(isLoading)

        return this.http.get<any>(url, httpHeaders).pipe(
            tap( response => {
                 //console.log("response", response);

            }),
            catchError(this.handleError)
        );    
    }

    public delete(url: string, httpHeaders: any): Observable<any> {

        return this.http.delete<any>(url).pipe(
            tap( response => {
                 //console.log("response", response);
            }),
            catchError(this.handleError)
        );      
    }

    private handleError(error: HttpErrorResponse) {

        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        // window.alert(errorMessage);
        console.log(error);
        return throwError(error);
    }

    async presentToast(position: 'top' | 'middle' | 'bottom', message, bgColor = 'success') {

        const toast = await this.toastController.create({
            message: message,
            duration: 1500,
            position: position,
            color: bgColor
        });

        await toast.present();
    }


    async presentLoading() {
        const loading = await this.loadingController.create({
          cssClass: "my-custom-class",
          message: "Loading .... ",
        });
        await loading.present();
      }
    public dismissLoading() {
        this.loadingController.dismiss();
      }
}
