import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service'
import { LoadingController, ToastController } from '@ionic/angular';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private toastController: ToastController
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {

            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.showErrors(err.error.errors);
                this.authService.logout();
            } else {
                if(err.error.errors)
                    this.showErrors(err.error.errors);    
                else if(err.message)
                    this.showErrors([err.message]);
            }    
                   
            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }   

    private showErrors(errors: any): void{ 
        let errorMessage = ``;
        let errorsList = Object.entries(errors);
        errorsList.forEach(function (error) {
            errorMessage += error[1] + '<br />';
        });
        this.presentToast('top', errorMessage);

    }

    async presentToast(position: 'top' | 'middle' | 'bottom', message) {
        const toast = await this.toastController.create({
            message: message,
            duration: 1500,
            position: position,
            color: 'danger'
        });

        await toast.present();
    }

}