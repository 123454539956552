import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { AuthService } from './services/auth.service';
import { APIHelperService } from './services/api-helper.service';
import { ErrorInterceptor  } from '../shared/components/interceptors/error.interceptor';
import { JwtInterceptor  } from '../shared/components/interceptors/jwt.interceptor';


@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
    ],
    providers: [
        AuthService,
        APIHelperService,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      ],
    declarations: [
    ],
    exports: [
        HttpClientModule,
        FormsModule,
        CommonModule
    ]

})
export class SharedModule { }