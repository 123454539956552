import { Component } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  user: any;
  public appPages;
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.user = this.authService.getLoggedInUser();
    this.appPages = [
      { title: 'Home Page', url: '/home', icon: 'home' },
      { title: 'Home', url: '/staff-home', icon: 'home' },
      { title: 'Face Attendance', url: '/staff-attendance-report', icon: 'staff-attendance' },
      {
        title: 'Events',
        url: '/events',
        icon: 'today',
        class: 'disabled-menu-item',
      },
      { title: 'Fees', url: '/fees', icon: 'cash' },
      {
        title: 'Assignment',
        url: '/assignment',
        icon: 'document-text',
        class: 'disabled-menu-item',
      },
      {
        title: 'Time Table',
        url: '/timetable',
        icon: 'time',
        class: 'disabled-menu-item',
      },
      { title: 'Result', url: '/result', icon: 'reader' },
      {
        title: 'Datesheet',
        url: '/datesheet',
        icon: 'newspaper',
        class: 'disabled-menu-item',
      },
      {
        title: 'Change Password',
        url: '/change-password',
        icon: 'key',
      },
    ];
    console.log(this.user);
    let code = localStorage.getItem('code');
    console.log(code);
    if (code) {
      this.router.navigateByUrl('/login');
    }
  }

  logout() {
    this.authService.logout();
  }

  checkIfTeacher() {
    if (this.user.user_type == 'staff') {
      return false;
    } else {
      return true;
    }
  }

  pageVisible(page) {
    if (page == 'Home Page') {
      return this.checkIfTeacher();
    }else if (page == 'Fees') {
      return this.checkIfTeacher();
    } else if (page == 'Assignment') {
      return this.checkIfTeacher();
    } else if (page == 'Result') {
      return this.checkIfTeacher();
    } else {
      return true;
    }
  }
}
