import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'code',
    pathMatch: 'full',
  },
  {
    path: 'folder/:id',
    loadChildren: () =>
      import('./folder/folder.module').then((m) => m.FolderPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'login2',
    loadChildren: () =>
      import('./pages/login2/login2.module').then((m) => m.Login2PageModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'attendance',
    loadChildren: () =>
      import('./pages/attendance/attendance.module').then(
        (m) => m.AttendancePageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'result',
    loadChildren: () =>
      import('./pages/result/result.module').then((m) => m.ResultPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'events',
    loadChildren: () =>
      import('./pages/events/events.module').then((m) => m.EventsPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'event-details',
    loadChildren: () =>
      import('./pages/event-details/event-details.module').then(
        (m) => m.EventDetailsPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'fees',
    loadChildren: () =>
      import('./pages/fees/fees.module').then((m) => m.FeesPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'pay-online',
    loadChildren: () =>
      import('./pages/pay-online/pay-online.module').then(
        (m) => m.PayOnlinePageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'show-payment-history',
    loadChildren: () =>
      import('./pages/payments/payments.module').then(
        (m) => m.PaymentsPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'assignment',
    loadChildren: () =>
      import('./pages/assignment/assignment.module').then(
        (m) => m.AssignmentPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'timetable',
    loadChildren: () =>
      import('./pages/timetable/timetable.module').then(
        (m) => m.TimetablePageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'result',
    loadChildren: () =>
      import('./pages/result/result.module').then((m) => m.ResultPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'datesheet',
    loadChildren: () =>
      import('./pages/datesheet/datesheet.module').then(
        (m) => m.DatesheetPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'ask-doubt',
    loadChildren: () =>
      import('./pages/ask-doubt/ask-doubt.module').then(
        (m) => m.AskDoubtPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'gallery',
    loadChildren: () =>
      import('./pages/gallery/gallery.module').then((m) => m.GalleryPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import('./pages/change-password/change-password.module').then(
        (m) => m.ChangePasswordPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'event-list',
    loadChildren: () =>
      import('./pages/event-list/event-list.module').then(
        (m) => m.EventListPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'details-page',
    loadChildren: () =>
      import('./pages/details-page/details-page.module').then(
        (m) => m.DetailsPagePageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'support',
    loadChildren: () =>
      import('./pages/support/support.module').then((m) => m.SupportPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'notification-page',
    loadChildren: () =>
      import('./pages/notification-page/notification-page.module').then(
        (m) => m.NotificationPagePageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'account-setting',
    loadChildren: () =>
      import('./pages/account-setting/account-setting.module').then(
        (m) => m.AccountSettingPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'student-list',
    loadChildren: () =>
      import('./pages/student-list/student-list.module').then(
        (m) => m.StudentListPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'code-verification',
    loadChildren: () =>
      import('./pages/code-verification/code-verification.module').then(
        (m) => m.CodeVerificationPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'subject',
    loadChildren: () =>
      import('./pages/subject/subject.module').then((m) => m.SubjectPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'chapter-list',
    loadChildren: () =>
      import('./pages/chapter-list/chapter-list.module').then(
        (m) => m.ChapterListPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'quiz',
    loadChildren: () =>
      import('./pages/quiz/quiz.module').then((m) => m.QuizPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'popup-component',
    loadChildren: () =>
      import('./pages/popup-component/popup-component.module').then(
        (m) => m.PopupComponentPageModule
      ),
    canLoad: [AuthGuard],
  },
  //Staff routs
  {
    path: 'staff-home',
    loadChildren: () =>
      import('./pages/staff-home/staff-home.module').then(
        (m) => m.StaffHomePageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'staff-attendance',
    loadChildren: () =>
      import('./pages/staff-attendance/staff-attendance.module').then(
        (m) => m.StaffAttendanceModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'gradebook',
    loadChildren: () =>
      import('./staff-pages/gradebook/gradebook.module').then(
        (m) => m.GradebookPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'take-attendance',
    loadChildren: () =>
      import('./staff-pages/attendance/attendance/attendance.module').then(
        (m) => m.AttendancePageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'staff-attendance-report',
    loadChildren: () =>
      import('./pages/staff-attendance-report/staff-attendance-report.module').then(
        (m) => m.StaffAttendanceReportModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'code',
    loadChildren: () =>
      import('./pages/code/code.module').then((m) => m.CodePageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
