import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url 
        
        if(request.url.indexOf("/login") == -1){
            const  authResponse = this.authService.getAuthResponse();
            const isLoggedIn = this.authService.isUserLoggedIn();
            const isApiURI = request.url.startsWith(environment.apiURI);

            if (isLoggedIn && isApiURI) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${authResponse.token}`
                    }
                });
            }
        }        

        return next.handle(request);
    }
}